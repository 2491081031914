@import "./mixin";

* {
  box-sizing: border-box;
}

html {
  scroll-margin-top: 120px;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 4px 0 0 4px;
  background-color: var(--secondary-color);
}

*::-moz-selection {
  background: var(--grey-lines-color);
}

*::selection {
  background: var(--grey-light-color);
  color: var(--white-color);
}

:root {
  //old primary - #ffdd2d
  //old primary-hover - #fcc521

  //new primary - #fecc00
  //new primary-hover - #ffdd2d
  --primary-color: #fecc00;
  --primary-color-15: #ffdd2d26;
  --boxshadow-primary-color: rgba(245, 195, 0, 0.5);
  --hover-primary-color: #ffdd2d;
  --orange-color: #fcbc17;
  --yellow-10: #fff3b7;
  --yellow-20: rgba(240, 215, 86, 0.82);
  --yellow-color-200: #ffdd2d;
  --secondary-color: #0b0b0b;
  --white-color: #ffffff;
  --bar-2: #ededed;
  --bar-3: #edf2f6;
  --grey: #9e9e9e;
  --grey-color: #616161;
  --grey-secondary: #e9e9e9;
  --grey-light-color: #c9ced6;
  --grey-darker-color: #9ea0a3;
  --grey-light-secondary: #f7f7f7;
  --grey-light-third: #f2f2f2;
  --light-grey-color-200: #f6f6f6;
  // ----
  --beige-p-color: #e3d1ac;
  --yellow-p-color: #ffe99d;
  --yellow-p-secondary-color: #f0d756;
  --grey-oragne-p-color: #ccc2b2;
  --grey-p-color: #e4d6cd;
  // ----
  --grey-fourth: #a3a3a3;
  --grey-third: #9ea0a3;
  --black-color: #000000;
  --grey-lines-color: #e3e3e3;
  --blue-background-color: #f7f8f9;
  --blue-color: #105ca8;
  --pale-blue-color: #e1eef8;
  --red-color: #ac0401;
  --red-0: #b8190b;
  --red-error: rgba(225, 29, 72, 1);
  --red-secondary-color: rgba(225, 29, 71, 0.5);
  --red-danger-color: #e54f6d;
  --green-color: #1ab28f;
  --beige-color-100: #ffd8bc;
  --green-secondary: #82c21d;
  --label-new-color: #f0ffd0;
  --label-license-color: #ffd8bc;
  --label-sale-color: #ffeec0;
  --light-yellow-color: #f8eeb9;
  --middle-yellow-color: #fbe77d;
  --remington-color: #e5b892;
  --border-radius-small: 8px;
  --border-radius-normal: 16px;
  --border-radius-medium: 24px;
  --fg-error: rgba(225, 29, 72, 1);
  --border-error: rgba(225, 29, 72, 1);
}

body,
html {
  @include clearStyles;
  // min-width: 320px;
  //font-family: "Gilroy";
  font-size: 14px;
  line-height: 24px;
  scroll-behavior: smooth;
  background-color: var(--white-color);
  color: var(--black-color);
}

body {
  position: relative;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
}

.without-scroll {
  overflow: hidden !important;
  padding-right: 4px;
}

h1,
h2,
h3,
h4,
h5,
p,
a,
li,
label,
span,
div {
  @include clearStyles;
}

ul {
  @include clearStyles;
  list-style-type: none;
}

a {
  text-decoration: none;
  color: var(--black-color);
}

input {
  @include clearStyles;
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: unset;
  appearance: none;

  &[type="number"] {
    appearance: none;
    -moz-appearance: textfield;

    &:hover,
    &:focus {
      appearance: none;
      -moz-appearance: textfield;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
}

button {
  @include clearStyles;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: var(--black-color);
}

img {
  width: 100%;
}

main {
  padding-bottom: 100px;
  min-height: calc(100svh - 118px);

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    padding-bottom: 60px;
    min-height: calc(100svh - 66px);
  }
}

section {
  padding: 20px 0;

  @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
    padding-bottom: 6px;
  }

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    padding: 12px 0 16px;
  }
}

.container {
  margin: 0 auto;
  padding: 0 120px;
  width: 100%;
  flex: 1;
  max-width: 1680px;

  @media only screen and (min-width: 1024px) and (max-width: 1439.98px) {
    max-width: 1320px;
    padding: 0 100px;
  }

  @media (min-width: 768px) and (max-width: 1023.98px) {
    max-width: 924px;
    padding: 0 50px;
  }

  @media screen and (max-width: 767.98px) {
    max-width: 736px;
    padding: 0 16px;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: wrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
}

[data-is-not-valid="true"] {
  border-color: var(--red-color) !important;
  animation: 0.1s tremor ease-out 1;
}

[data-style-custom="links-wrap"] {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

[data-style-custom="custom-desc-link"] {
  background-color: var(--blue-background-color);
  padding: 4px 8px;
  border-radius: var(--border-radius-small);
  border: none;
  transition: 0.25s;

  &:hover {
    background-color: var(--primary-color);
  }

  &::after,
  &::before {
    display: none;
  }
}

@keyframes tremor {
  0%,
  25% {
    left: -1px;
    transform: translateX(-1%);
  }

  50%,
  100% {
    left: 1px;
    transform: translateX(1%);
  }
}

.consultant-icon {
  background-color: var(--primary-color) !important;

  @media only screen and (max-width: 767px) {
    width: 0 !important;
    height: 0 !important;
  }
}

.consultant-widget-wrap {
  visibility: hidden !important;
  pointer-events: none !important;
}

.messengers__item[data-v-a2b57e0e] {
  @media only screen and (max-width: 767px) {
    visibility: hidden !important;
    pointer-events: none !important;
  }
}

.consultant-header[data-v-5caba0b6],
  // .contact-form__btn[data-v-621716fe] {
.contact-form__btn {
  background-color: var(--primary-color) !important;
  color: var(--black-color) !important;
  -webkit-text-fill-color: var(--black-color) !important;
}

.consultant-header__name[data-v-5caba0b6] {
  color: var(--black-color) !important;
}

.consultant-header__close svg[data-v-5caba0b6] {
  fill: var(--black-color) !important;
}

#retailcrm-consultant-app {
  z-index: 1;
}

.consultant-widget-wrap[data-v-47086672] {
  @media only screen and (max-width: 767px) {
    display: none !important;
  }
}

.grecaptcha-badge {
  display: none;
}

.check-elem {
  padding: 2px;
  border: 1px solid var(--red-color);
}

div.f-thumbs__slide.for-image.is-nav-selected {
  border: 1px solid black;
  border-radius: 4px;
  opacity: 1;
}

.f-button {
  box-shadow: 0 0 8px 0 #00000026 !important;
  padding: 18px !important;
  background-color: var(--white-color) !important;
  height: 60px !important;
  width: 60px !important;

  & > svg {
    filter: none !important;
    stroke: var(--secondary-color) !important;
    transition: 0.1s ease-in !important;
    transition-delay: 0s !important;
    width: 20px !important;
    height: 20px !important;
  }

  &:hover {
    background-color: var(--white-color) !important;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.32) !important;
  }

  @media screen and (max-width: 1023px) {
    width: 50px !important;
    height: 50px !important;
  }
}

.sale-background {
  //background-color: var(--white-color);
  //background-image: url("/images/pictures/sale-background.png");
  //background-repeat: repeat;
  //background-size: 500px 500px;
}

.title {
  @include font24;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 24px;

  &Link {
    @include font14;
    position: relative;
    display: flex;
    gap: 16px;
    align-items: center;
    font-weight: 500;
    color: var(--grey-color);

    &::before {
      content: "";
      width: 0;
      height: 2px;
      background-color: var(--grey-color);
      transition: width 0.2s ease;
    }

    &::after {
      content: "";
      width: 32px;
      height: 2px;
      background-color: var(--grey-color);
      transition: width 0.2s ease;
    }

    &:hover {
      &::before {
        width: 16px;
      }

      &::after {
        width: 16px;
      }
    }
  }
}
